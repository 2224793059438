import { createLocalVue, shallowMount } from '@vue/test-utils';
import Vuex from 'vuex';

import { nameSpace as PaymentMethodsHubNameSpace } from './stores/PaymentMethodsHub';

import ListPaymentMethods from './ListPaymentMethods.vue';

jest.mock('@/composable/App/Translations', () => ({
  useTranslations: () => ({
    $t: key => `phraseapp_tree.${key}`,
  }),
}));

const paymentMethodsStore = {
  namespaced: true,
  state: {
    paymentMethodsCollectionB2B: [
      {
        holder: 'holder-3',
        number: '5555',
        type: 'visa',
        uuid: 'uuid-B',
      },
    ],
    paymentMethodsCollectionB2C: [
      {
        holder: 'holder-1',
        number: '4444',
        type: 'visa',
        uuid: 'uuid-pm-A',
        userProfile: [{
          uuid: 'uuid-profile-1',
        }],
      },
      {
        holder: 'holder-2',
        number: '7777',
        type: 'visa',
        uuid: 'uuid-pm-C',
        userProfile: [{
          uuid: 'uuid-profile-2',
        }],
      },
    ],
  },
  getters: {
    getDefaultPaymentMethodUUID: () => jest.fn().mockResolvedValue('A'),
  },
};

const mockedModules = {
  [PaymentMethodsHubNameSpace]: paymentMethodsStore,
};

const localVue = createLocalVue();
localVue.use(Vuex);

const store = new Vuex.Store({
  modules: mockedModules,
});

let wrapper;

describe('Given ListPaymentMethods component', () => {
  beforeEach(() => {
    wrapper = shallowMount(ListPaymentMethods, {
      propsData: {
        profile: {
          companyUuid: null,
          isAdminInCompanyPays: false,
          isBusinessInCompanyPays: false,
          isBusinessProfile: false,
          profileType: 'user',
          profileUuid: '1',
          userUuid: 'uuid-user',
        },
      },
      computed: {
        getDefaultPaymentMethodUUID: () => jest.fn(),
      },
      localVue,
      store,
    });
  });

  afterEach(() => {
    jest.resetAllMocks();
  });

  describe('When the payment methods collection is initialized', () => {
    it('Then the "getDefaultPaymentMethodUUID" method is called', async () => {
      expect(wrapper.vm.getDefaultPaymentMethodUUID).toHaveBeenCalled();
    });
  });

  describe('When the profile is personal', () => {
    const expected = [
      {
        holder: 'holder-1',
        number: '4444',
        type: 'visa',
        uuid: 'uuid-pm-A',
        userProfile: [{
          uuid: 'uuid-profile-1',
        }],
      },
      {
        holder: 'holder-2',
        number: '7777',
        type: 'visa',
        uuid: 'uuid-pm-C',
        userProfile: [{
          uuid: 'uuid-profile-2',
        }],
      },
    ];

    it(`Then the paymentMethodsCollection will be ${JSON.stringify(expected)}`, () => {
      wrapper = shallowMount(ListPaymentMethods, {
        propsData: {
          profile: {
            isBusinessProfile: false,
            isBusinessInCompanyPays: false,
          },
        },
        localVue,
        store,
      });

      expect(wrapper.vm.paymentMethodsCollection).toStrictEqual(expected);
    });
  });

  describe('When the profile is business and the employee pays', () => {
    const expected = [
      {
        holder: 'holder-3',
        number: '5555',
        type: 'visa',
        uuid: 'uuid-B',
      },
    ];

    it(`Then the paymentMethodsCollection will be ${JSON.stringify(expected)}`, () => {
      wrapper = shallowMount(ListPaymentMethods, {
        propsData: {
          profile: {
            isBusinessProfile: true,
            isBusinessInCompanyPays: false,
          },
        },
        localVue,
        store,
      });

      expect(wrapper.vm.paymentMethodsCollection).toStrictEqual(expected);
    });
  });

  describe('When the profile is business and the company pays', () => {
    const expected = [{
      holder: 'holder-3',
      number: '5555',
      type: 'visa',
      uuid: 'uuid-B',
    }];

    it(`Then the paymentMethodsCollection will be ${JSON.stringify(expected)}`, () => {
      wrapper = shallowMount(ListPaymentMethods, {
        propsData: {
          profile: {
            isBusinessProfile: true,
            isBusinessInCompanyPays: true,
          },
        },
        localVue,
        store,
      });

      expect(wrapper.vm.paymentMethodsCollection).toStrictEqual(expected);
    });
  });
});
